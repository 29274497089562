@use "setting" as s;
@use "function/_function" as f;

.c-title-wrapper {
  display: flex;
  align-items: center;
  @include s.mq(smd) {
    margin-bottom: 40px;
  }
  @include s.mq(md) {
    margin-top: 23px;
    margin-bottom: 30px;
  }
}