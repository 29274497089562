@use "setting" as s;
@use "function/_function" as f;

.p-movie-category-regist {
  position: relative;
  display: flex;
  flex-direction: column;
  @include s.mq(smd) {
    min-height: 100vh;
    .l-inner {
      width: 42.5%;
    }
  }
  &__header {
    position: relative;
    z-index: 2;
  }
  &__button {
    @include s.mq(smd) {
      max-width: 280px;
      margin-left: auto;
    }
    @include s.mq(md) {
      margin-bottom: 44px;
    }
  }
}