@use "setting" as s;
@use "function/_function" as f;

.c-title {
  &01 {
    display: inline-block;
    font-weight: 700;
    color: s.$title-color;
    @include s.mq(smd) {
      font-size: 23px;
      line-height: calc(40 / 23);
    }
    @include s.mq(md) {
      font-size: 20px;
      line-height: calc(34 / 20);
    }
  }
}