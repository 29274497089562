@use "setting" as s;
@use "function/_function" as f;

.c-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 32px;
  &__item  {
    margin-left: 15px;
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: #fff;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    font-size: 13px;
    line-height: 1;
    &.-current {
      background: s.$base-color;
      color: #fff;
    }
    &:hover {
      color: #fff;
      background: s.$base-color;
      opacity: 1;
    }
  }
}
