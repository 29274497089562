@use "setting" as s;
@use "function/_function" as f;

.c-store-list-form {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  padding-bottom: 16px;
  @include s.mq(xxxl) {
    overflow-x: auto;
  }
  &__inner {
    @include s.mq(smd) {
      min-width: 960px;
      overflow-x: auto;
    }
    @include s.mq(md) {
      font-size: 12px;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    background: s.$base-color;
    border-radius: 10px 10px 0 0;
    height: 34px;
    color: #fff;
    line-height: calc(24 / 14);
    padding-left: 34px;
    @include s.mq(md) {
      padding-right: 20px;
      padding-left: 20px;
      font-size: 12px;
    }
    .c-store-list-form__title,.c-store-list-form__userid {
      span {
        position: relative;
        padding-right: 24px;
        cursor: pointer;
        &::after{
          display: block;
          content:"";
          background-image: url(../images/icon/icon-sort-arrow.svg);
          background-repeat: no-repeat;
          background-position: right center;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 6px;
        }
        &.-desc::after {
          transform: translateY(-50%) rotate(180deg); // 180度回転
        }
      }
    }
  }
  &__group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 17px 34px;
    &:not(:last-child) {
      position: relative;
    }
    @include s.mq(md) {
      padding: 17px 20px;
    }
    &::after {
      position: absolute;
      content: "";
      width: calc(100% - 52px);
      height: 1px;
      background: #C3C3C3;
      left: 26px;
      bottom: 0;
      @include s.mq(md) {
        width: calc(100% - 20px);
        left: 10px;
      }
    }
  }
  &__title {
    padding-right: 24px;
    @include s.mq(smd) {
      width: 490px;
    }
    @include s.mq(md) {
      width: 69.5%;
      padding-right: 14px;
    }
  }
  &__userid {
    @include s.mq(smd) {
      width: 98px;
      margin-left: 16px;
      text-align: center;
    }
    @include s.mq(md) {
      width: 89px;
      margin-left: auto;
    }
  }
  .c-button-wrap {
    @include s.mq(md) {
      margin-top: 10px;
      margin-left: auto;
      width: 168px;
    }
  }
}