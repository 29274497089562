@use "setting" as s;
@use "function/_function" as f;

.c-store-movie {
  &__header {
    display: flex;
    cursor: pointer;
    @include s.mq(smd) {
      margin-bottom: 40px;
    }
    @include s.mq(md) {
      margin-top: 23px;
      margin-bottom: 30px;
    }
    .c-title01 {
      position: relative;
      padding-right: 43px;
      .c-accordion-icon {
        position: absolute;
        top: 10px;
        right: 0;
        display: block;
        background: #afafaf;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
      .c-accordion-icon::before,
      .c-accordion-icon::after {
        position: absolute;
        content: "";
        width: 10px;
        height: 1px;
        background: #fff;
        transition: 0.3s;
      }
      @include s.mq(md) {
        .c-accordion-icon::before,
        .c-accordion-icon::after {
          width: 10px;
        }
      }
      .c-accordion-icon::before {
        right: 5px;
        top: 50%;
      }
      .c-accordion-icon::after {
        right: 5px;
        top: 50%;
        rotate: 90deg;
      }
      .c-accordion-icon.is-active::after {
        rotate: 0deg;
      }
    }
  }
  &__new-inner {
    overflow-y: auto;
    max-height: 760px;
    @include s.mq(smd) {
      padding-right: 20px;
    }
    @include s.mq(md) {
      padding-right: 8px;
    }
    &::-webkit-scrollbar {
      @include s.mq(smd) {
        width: 9px;
      }
      @include s.mq(md) {
        width: 5px;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #daecf8; /* Scrollbar color */
      border-radius: 3px;
    }
  }
  &__category-body {
    display: none;
    .c-store-movie__body {
      display: block;
      margin-bottom: 7px;
    }
  }
  &__largecategory {
    &-inner {
      display: none;
      overflow-y: auto;
    }
    &-title {
      position: relative;
      font-size: 18px;
      line-height: calc(19 / 18);
      color: s.$base-color;
      .c-accordion-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        background-image: url(../images/icon/icon-bottom-arrow.svg);
        background-repeat: no-repeat;
        width: 14px;
        height: 8px;
        &.is-active {
          rotate: 180deg;
        }
      }
    }
  }
  &__mediumcategory {
    &:first-child {
      margin-top: 30px;
    }
    &-title {
      position: relative;
      margin-top: 6px;
      padding: 12px 20px;
      border-radius: 4px;
      background: s.$base-color;
      font-size: 18px;
      line-height: calc(19 / 18);
      color: #fff;
      font-weight: 700;
      .c-accordion-icon {
        position: absolute;
        top: 10px;
        right: 0;
        display: block;
        background: #ddd;
        width: 15;
        height: 15;
        border-radius: 50%;
      }
      .c-accordion-icon::before,
      .c-accordion-icon::after {
        position: absolute;
        content: "";
        width: 15px;
        height: 1px;
        background: #fff;
        transition: 0.3s;
      }
      @include s.mq(md) {
        .c-accordion-icon::before,
        .c-accordion-icon::after {
          width: 15px;
        }
      }
      .c-accordion-icon::before {
        right: 20px;
        top: 12px;
      }
      .c-accordion-icon::after {
        right: 20px;
        top: 12px;
        rotate: 90deg;
      }
      .c-accordion-icon.is-active::after {
        rotate: 0deg;
      }
    }
    &-inner {
      display: none;
      overflow-y: auto;
      @include s.mq(smd) {
        padding: 15px 20px;
      }
      @include s.mq(md) {
        padding: 15px 0;
      }
      &::-webkit-scrollbar {
        @include s.mq(smd) {
          width: 9px;
        }
        @include s.mq(md) {
          width: 5px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: #daecf8; /* Scrollbar color */
        border-radius: 3px;
      }
      .c-store-movie__item {
        padding: 20px 0;
        &:first-child {
          border-top: 1px solid #ddd;
        }

      }
    }
  }

  &__body {
    display: none;

    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @include s.mq(smd) {
      padding: 24px 40px;
      width: 989px;
    }
    @include s.mq(md) {
      padding: 19px;
      width: 100%;
    }
    &.-new {
      @include s.mq(smd) {
        padding: 24px 20px 24px 40px;
      }
      @include s.mq(md) {
        padding: 19px;
      }
    }
  }
  &__item {
    border-bottom: 1px solid #ddd;;
    &:not(:first-child) {
      padding-top: 20px;
      @include s.mq(md) {
        padding-top: 12px;
      }
    }
    padding-bottom: 20px;
    @include s.mq(md) {
      padding-bottom: 12px;
    }
  }
  &__link {
    font-size: 14px;
    line-height: calc(19 / 14);
    text-decoration: underline;
    @include s.mq(md) {
      font-size: 12px;
    }

  }
}

.js-accordion {
  cursor: pointer;
}