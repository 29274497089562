@use "setting" as s;
@use "function/_function" as f;

.c-button-wrap {
  display: flex;
}

.c-button {
  background: s.$gradient-color;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  &.-large  {
    width: 493px;
    height: 42px;
    border-radius: 6px;
    text-align: center;
  }
  &.-small  {
    width: 115px;
    height: 29px;
    border-radius: 6px;
    text-align: center;
  }
  &.-add {
    margin-left: 25px;
    border-radius: 7px;
    background: s.$sub-color;
    font-size: 13px;
    letter-spacing: 0.05em;
  }
}

.c-button02 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79px;
  height: 32px;
  border-radius: 7px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);

  &.-edit {
    background: s.$edit-button-color;
  }
  &.-delete {
    margin-left: 10px;
    background: s.$base-color;
    color: #fff;
  }
}