.p-store-list {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  &__form-group {
    margin-top: 0;
  }
  &__input {
    margin-top: 0;
  }
  &__button {
    margin-left: 15px;
  }
  &__button-wrap {
    margin-left: auto;
  }
}