@use "setting" as s;
@use "function/_function" as f;

.c-mypage {
  &__table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @include s.mq(xxxl) {
      width: 960px;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
    @include s.mq(md) {
      width: 100%;
      font-size: 12px;
      white-space: nowrap;
    }
  }

  th {
    background: s.$base-color;
    font-size: 14px;
    color: #fff;
    line-height: calc(24 / 14);
    @include s.mq(md) {
      font-size: 12px;
    }
  }
  td {
    height: 82px;
    vertical-align: middle;
  }

  &__cell {
    padding: 5px 10px;
    text-align: left;
  }

  th,td {
    &.-username,&.-password {
      text-align: center;
    }
  }
  .-userid{
    @include s.mq(smd) {
      min-width: 150px;
    }
  }
  .-username,.-password {
    @include s.mq(smd) {
      min-width: 170px;
    }
  }

  .-topleft {
    padding-left: 46px;
    border-top-left-radius: 10px;
    @include s.mq(md) {
      padding-left: 18px;
    }
  }

  .-topright {
    width: 49.798%;
    border-top-right-radius: 10px;
  }

  .-bottomleft {
    padding-left: 46px;
    border-bottom-left-radius: 10px;
    @include s.mq(md) {
      padding-left: 18px;
    }
  }

  .-bottomright {
    width: 49.798%;
    border-bottom-right-radius: 10px;
    @include s.mq(smd) {
      padding-left: 30px;
    }
  }

  td.-password {
      letter-spacing: -0.3em;
      &.is-active {
        letter-spacing: 0;
      }
  }
}