@use "setting" as s;
@use "function/_function" as f;

.u-flex-center {
  display: flex;
  justify-content: center;
}

.u-mauto {
  margin-right: auto;
  margin-left: auto;
}

.u-mb30 {
  margin-bottom: 30px;
}

.u-mt60 {
  @include s.mq(smd) {
    margin-top: 60px;
  }
}

.u-smt40 {
  @include s.mq(md) {
    margin-top: 40px;
  }
}

.u-pr {
  position: relative;
}