@use "setting" as s;
@use "function/_function" as f;

.c-chart-button {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  @include s.mq(smd) {
    margin-right: 20px;
  }
  &__item {
    width: 59px;
    height: 22px;
    border: 1px solid #afafaf;
    border-radius: 15px;
    font-size: 14px;
    &:not(:first-child) {
      margin-left: 7px;
    }
    &.-active {
      background: s.$base-color;
      border: 1px solid s.$base-color;
      color: #fff;
    }
  }
}