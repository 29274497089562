@use "setting" as s;
@use "function/_function" as f;

.l-contents {
  padding: 27px 20px;
  background: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  + .l-contents {
    margin-top: 25px;
  }
}
