@use "setting" as s;
@use "function/_function" as f;

body {
  width: 100%;
  background: s.$body-color;
  color: #37435A;
  font-size: 16px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: 500;
  @media (max-width: 1280px) and (min-width: 751px) {
    width: 1280px;
    overflow-x: scroll;
  }
}

html {
  text-size-adjust:100%;
  -webkit-text-size-adjust:100%;
  &.is-active {
    overflow: hidden;
  }
}

img {
  vertical-align: bottom;
  display: block;
  max-width: 100%;
}

a,button {
  transition: 0.3s ease;
}
a:hover,button:hover {
  opacity: 0.6;
  transition: 0.3s ease;
}

::placeholder {
  color: #c7cad5;
}

:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
}