@use "setting" as s;
@use "function/_function" as f;

.c-movie-title {
  max-width: 815px;
  padding-left: 22px;
  border-left: 1px solid #707070;
  @include s.mq(smd) {
    font-size: 18px;
    line-height: calc(27 /  18);
  }
  @include s.mq(md) {
    font-size: 13px;
    line-height: calc(21 /  13);
  }
}