@use "setting" as s;
@use "function/_function" as f;

.c-burger {
  display: none;
  @include s.mq(md) {
    width: 27px;
    height: 8px;
    display: block;
    position: absolute;
    left: 20px;
    cursor: pointer;

    &.is-active {
      .c-burger__line--top {
        rotate: 45deg;
        top: 6px;
      }
      .c-burger__line--bottom {
        rotate: -45deg;
        bottom: 8px;
      }
    }
    &__line {
      width: 27px;
      height: 1px;
      background: s.$sub-color;
      display: block;
      transition: 0.2s;

      position: absolute;
      left: 0;
      &--top {
        top: 0;
      }
      &--bottom {
        top: 8px;
        transform: translateY(-50%);
      }
    }
  }
}
