@use "setting" as s;
@use "function/_function" as f;

.p-login {
  @include s.mq(smd) {
    margin: 125px auto 0;
    padding: 42px 64px 51px;
    width: 724px;
    height: 410px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  }
  @include s.mq(md) {
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    height: 100%;
  }
  &__title {
    font-weight: 700;
    color: s.$base-color;
    @include s.mq(smd) {
      font-size: 26px;
      line-height: calc(44 / 26);
    }
    @include s.mq(md) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 20px;
      line-height: calc(34 / 24);
    }
  }
  &__footer {
    height: 42px;
    @include s.mq(smd) {
      width: 171px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 45px;
    }
    @include s.mq(md) {
      margin-top: 26px;
    }
  }
  &__button  {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: s.$gradient-color;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-align: center;
  }
}
