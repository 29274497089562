@use "setting" as s;
@use "function/_function" as f;

.l-side-header {
  width: 197px;
  background: #f5f7f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  @include s.mq(md) {
    position: fixed;
    z-index: 999;
    top: 50px;
    left: -120%;
    transition: all 0.6s;
    flex-grow: 1; /* 親要素の高さに合わせる */
    &.is-open{
      left: 0;
      height: 100%;
    }
  }

  &__name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 197px;
    height: 47px;
    background: s.$gradient-color;
    font-size: 17px;
    font-weight: 700;
    color: #fff;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link {
    display: block;
    width: 100%;
    padding-top: 13px;
    padding-bottom: 13px;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    color: s.$text-color01;
    text-align: center;
    &::after {
      position: absolute;
      top: 18px;
      right: 18px;
      background: url(../images/icon/icon-right-arrow.svg);
      content: "";
      width: 6px;
      height:  9px;
    }
    &.-title {
      background: #E3E6E8;
      &::after {
        display: none;
        content: none;
      }
    }
    &.-current {
      background: #EAEAEA;
    }
    &.-store-list {
      &::before {
        position: absolute;
        top: 11px;
        left: 18px;
        background: url(../images/icon/icon-store.svg);
        content: "";
        width: 21px;
        height:  21px;
      }
    }
    &.-store {
      &::before {
        position: absolute;
        top: 11px;
        left: 18px;
        background: url(../images/icon/icon-store-add.svg);
        content: "";
        width: 21px;
        height:  21px;
      }
    }
    &.-movie-list {
      &::before {
        position: absolute;
        top: 11px;
        left: 18px;
        background: url(../images/icon/icon-movie.svg);
        content: "";
        width: 21px;
        height:  21px;
      }
    }
    &.-movie {
      &::before {
        position: absolute;
        top: 11px;
        left: 18px;
        background: url(../images/icon/icon-movie-add.svg);
        content: "";
        width: 21px;
        height:  21px;
      }
    }
    &.-movie-category {
      &::before {
        position: absolute;
        top: 11px;
        left: 18px;
        background: url(../images/icon/icon-movie-category.svg);
        content: "";
        width: 21px;
        height:  21px;
      }
    }
    &.-profile {
      &::before {
        position: absolute;
        top: 11px;
        left: 18px;
        background: url(../images/icon/icon-profile.svg);
        content: "";
        width: 21px;
        height:  21px;
      }
    }
    &.-session {
      &::before {
        position: absolute;
        top: 11px;
        left: 18px;
        background: url(../images/icon/icon-session.svg);
        content: "";
        width: 21px;
        height:  21px;
      }
    }
  }
}