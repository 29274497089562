@use "setting" as s;
@use "function/_function" as f;

.l-page-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 10;
  @include s.mq(smd) {
    padding-top: 20px;
    padding-right: 26px;
  }
  @include s.mq(md) {
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    padding: 12px 20px;
  }
  &__name {
    margin-right: 26px;
    font-family: YuGothic;
    font-weight: 700;
    color: #208bd3;
    letter-spacing: 0.05em;
    @include s.mq(smd) {
      margin-right: 26px;
    }
    @include s.mq(md) {
      margin-right: 10px;
      font-size: 13px;
      line-height: calc(23 / 13);
    }
  }
  &__logout {
    width: 131px;
    height: 26px;
    border-radius: 13px;
    background: s.$gradient-color;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 15px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.05em;
    @include s.mq(md) {
      width: 103px;
      font-size: 13px;
      line-height: calc(23 / 13);
    }
  }
}