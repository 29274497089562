@use "setting" as s;
@use "function/_function" as f;

.c-movie-category-regist {
  background: #fff;
  @include s.mq(md) {
    margin-top: auto;
  }
  @include s.mq(smd) {
    position: absolute;
    right: 0;
    top: 0;
    width: 42.5%;;
    height: 100vh;
    z-index: 1;
  }
  @media (max-width: 1280px) and (min-width: 751px) {
    position: absolute;
    min-width: 540px;
  }
  &__inner {
    @include s.mq(smd) {
      padding: 129px 45px 90px;
    }
    @include s.mq(md) {
      padding: 8px 20px 28px 20px;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__name {
    font-size: 14px;
    line-height: calc(19 / 14);
    color: s.$text-color02;
    &.-medium {
      &::before {
        display: inline-block;
        content: "-";
        margin-right: 4px;
        margin-left: 4px;
      }
    }
  }
}