@use "setting" as s;
@use "function/_function" as f;

.c-session {
  &__title {

    font-size: 16px;
    font-weight: 700;
    color: s.$base-color;
    @include s.mq(smd) {
      margin-left: 20px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__chart-wrapper {
    @include s.mq(md) {
      overflow-x: auto; // 横スクロールを有効にする
      -webkit-overflow-scrolling: touch; // スムーズスクロールを有効にする
      width: 100%; // 親要素の幅を全体に設定
      padding-bottom: 5px;
      &::-webkit-scrollbar {
        @include s.mq(md) {
          height: 9px; // スクロールバーの高さを9pxに設定
        }
      }

      &::-webkit-scrollbar-thumb {
        background: #DAECF8; /* スクロールバーの色 */
        border-radius: 50px; // スクロールバーの角を丸くする
      }

      &::-webkit-scrollbar-track {
        background: #F5F7F8; /* スクロールバーの通り道の色 */
        border-radius: 50px; // 通り道の角を丸くする
      }
    }
  }
  &__chart {
    @include s.mq(md) {
      display: inline-block; // 子要素をインラインブロックに設定
      width: auto; // 幅を自動に設定

      canvas {
        display: block; // キャンバスもブロック要素に設定
        width: 760px; // キャンバスの幅を固定
      }
    }
  }
  &__pageview {
    margin: 34px auto 0;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    @include s.mq(smd) {
      width: calc(100% - 40px);
    }
    &-title {
      font-size: 16px;
      line-height: calc(27 / 16);
      font-weight: 700;
      color: s.$base-color;
    }
    &-num {
      font-family: "DIN 2014";
      font-size: 43px;
      line-height: calc(56 / 43);
      color: #37435a;

    }
  }
  &__csv {

    margin: 45px 0;
    @include s.mq(smd) {
      display: flex;
      align-items: center;
    }
    @include s.mq(md) {
      margin-top: 65px;
    }
    &-title {
      font-size: 16px;
      font-weight: 700;
      color: s.$base-color;
      @include s.mq(smd) {
        margin-left: 20px;
      }
    }
    &-download {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 42px;
      border-radius: 6px;
      background: linear-gradient(#208bd3 0%, #1f88cf 0%, #0075c3 100%);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      font-family: YuGothic;
      font-size: 16px;
      color: #fff;
      @include s.mq(smd) {
        width: 171px;
        margin-left: 42px;
      }
      @include s.mq(md) {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
}