@use "setting" as s;
@use "function/_function" as f;

.l-header {
  background: s.$gradient-color;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  &__inner {
    display: flex;
    align-items: center;
    max-width: 1280px;
    height: 47px;
    @include s.mq(smd) {
      padding-right: 33px;
      padding-left: 33px;
    }
    @include s.mq(md) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  &__name  {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
  }
}