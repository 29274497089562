@use "setting" as s;
@use "function/_function" as f;

.c-play {
  margin-top: 35px;
  @include s.mq(smd) {
    max-width: 960px;
    padding-right: 20px;
  }
  &__inner  {
    @include s.mq(smd) {
      background: #fff;
      border-radius: 10px;
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16));
      padding: 34px 46px;
    }
    @include s.mq(sxl) {
      padding: 34px 92px;
    }
  }
  video {
    width: 100%;   // 幅を親要素に合わせて100%に設定
    height: auto;  // 高さを自動調整
  }
}