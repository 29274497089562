@use "setting" as s;
@use "function/_function" as f;

.c-form  {
  &__group  {
    margin-top: 24px;
  }
  &__input {
    width: 100%;
    margin-top: 10px;
    border-radius: 7px;
    background: #fff;
    border: 1px solid #ddd;
    line-height: calc(26 / 16);
    padding: 6px 16px;
    color: s.$text-color02;
    &.-edit {
      color: s.$edit-color;
    }
  }
  &__textarea {
    width: 100%;
    min-height: 94px;
    margin-top: 10px;
    border-radius: 7px;
    background: #fff;
    border: 1px solid #ddd;
    line-height: calc(26 / 16);
    padding: 6px 16px;
    color: s.$text-color02;
  }
  &__label {
    display: block;
  }
  &__select {
    position: relative;

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 27px;
      right: 25px;
      width: 10px;
      height: 6px;
      background: url(../images/icon/icon-bottom-arrow.svg);
    }
    select{
      color: s.$text-color02;
    }

  }
  &__button {
    margin-top: 41px;
  }
  &__caution {
    color: s.$text-color01;
    opacity: 0.6;
    @include s.mq(smd) {
      margin-top: 12px;
    }
    @include s.mq(md) {
      margin-top: 8px;
      font-size: 13px;
    }
  }
}