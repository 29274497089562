@use "setting" as s;
@use "function/_function" as f;

.c-movie-list-form {
  width: 100%;
  font-size: 14px;
  padding-bottom: 16px;
  @include s.mq(xxxl) {
    overflow-x: auto;
    &::-webkit-scrollbar {
      @include s.mq(md) {
        height: 9px; // スクロールバーの高さを9pxに設定
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DAECF8; /* スクロールバーの色 */
      border-radius: 50px; // スクロールバーの角を丸くする
    }

    &::-webkit-scrollbar-track {
      background: #fff; /* スクロールバーの通り道の色 */
      border-radius: 50px; // 通り道の角を丸くする
    }
    @include s.mq(md) {
      transform: rotateX(180deg);
    }
  }
  &__inner {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @include s.mq(xxxl) {
      min-width: 960px;

    }
    @include s.mq(md) {
      min-width: 796px;
      font-size: 12px;
      transform: rotateX(180deg);
    }
  }
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    background: s.$base-color;
    border-radius: 10px 10px 0 0;
    height: 34px;
    color: #fff;
    line-height: calc(24 / 14);
    padding-left: 34px;
    @include s.mq(md) {
      padding-left: 20px;
      font-size: 12px;
    }
  }
  &__group {
    display: flex;
    align-items: center;
    padding: 17px 34px;
    &:not(:last-child) {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: calc(100% - 52px);
        height: 1px;
        background: #C3C3C3;
        left: 26px;
        bottom: 0;
        @include s.mq(md) {
          width: calc(100% - 20px);
          left: 10px;
        }
      }
    }
    @include s.mq(md) {
      padding: 17px 20px;
    }
  }
  &__title {
    padding-right: 24px;
    width: 324px;
    @include s.mq(md) {
      width: 278px;
      padding-right: 14px;
    }
  }
  &__large-category,&__medium-category {
    width: 127px;
    text-align: center;
    @include s.mq(md) {
      width: 96px;
    }
  }
  &__date {
    width: 146px;
    padding-right: 47px;
    padding-left: 24px;
    text-align: center;
    @include s.mq(md) {
      width: 100px;
      padding: 0;
    }
  }
  .c-button-wrap {
    @include s.mq(md) {
      margin-left: auto;
      width: 168px;
    }
  }
}